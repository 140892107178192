import React from "react";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal } from "antd";
const { confirm } = Modal;
// import { TbWheel } from "react-icons/tb";

const Menu = ({ history }) => {
  const addressStatus = localStorage.getItem("addressStatus");
  const petStatus = localStorage.getItem("petStatus");
  const profireStatus = localStorage.getItem("member_status");
  const accepted_campaign = localStorage.getItem("accepted_campaign");

  const showPromiseConfirm = () => {
    Modal.confirm({
      title: "Please complete your profile",
      content: "You need to complete your profile before upload receipt",
      onOk()  {
        window.location.href = "profile";
      }
    });
  }
  const checkPermission = () => {
    // UAT test ... //
    // window.location.href = "Upload_receipt";
    // ..... //
    
    if (addressStatus === "1" && petStatus === "1" && profireStatus === "1") {
      window.location.href = "Upload_receipt";
    } else {
      showPromiseConfirm();
    }
  }

  const checkPermissionLuckyDraw = () => {
    // return false
    if (addressStatus === "1" && petStatus === "1" && profireStatus === "1" && accepted_campaign === "true") {
      window.location.href = "lucky-draw-exchange";
    } else {
      window.location.href = "lucky-draw";
    }
  }
  return (
    <div>
      <Row className="align-items-center menu">
        <Col style={{ margin: "0", padding: "0" }}>
          <Button
            style={{ backgroundColor: "#FF6699", borderColor: "#FF6699" }}
            className="menu_icon_home"
            href="home"
          ></Button>
          <br></br>
          <b>
            หน้าแรก
          </b>
        </Col>
        <Col style={{ margin: "0", padding: "0" }}>
          <Button
            style={{ backgroundColor: "#FF6699", borderColor: "#FF6699" }}
            className="menu_icon_profile"
            href="profile"
          ></Button>
          <br></br>
          <b>
            โปรไฟล์
          </b>
        </Col>

        <Col style={{ margin: "0", padding: "0"}}>
          <Button
            style={{ backgroundColor: "#FF6699", borderColor: "#FF6699" }}
            className="menu_icon_upload"
            // href="Upload_receipt"
            onClick={checkPermission}
            disabled
          ></Button>
            <br></br>
          <b style={{color:'#e4e4e4'}}>
            อัปโหลด
          </b>
        </Col>

        <Col style={{ margin: "0", padding: "0" }}>
          <Button
            style={{ backgroundColor: "#FF6699", borderColor: "#FF6699" }}
            className="menu_icon_lucky_draw"
            // href="lucky-draw"
            onClick={checkPermissionLuckyDraw}
            // disabled={true}
          >
          </Button>
          <br></br>
          <b>
            แลกคะแนน
          </b>
        </Col>

        <Col style={{ margin: "0", padding: "0" }}>
          <Button
            style={{ backgroundColor: "#FF6699", borderColor: "#FF6699" }}
            className="menu_icon_redeem"
            href="redeem_rewards"
          ></Button>
          <br></br>
          <b>
            แลกรางวัล
          </b>
        </Col>
      </Row>
    </div>
  )
};
export default Menu;
