import React from "react";
import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { useDispatch } from "react-redux";

const UserRoute = ({ children, ...rest }) => {
  // const { user } = useSelector((state) => ({ ...state }));

  //---- UAT ใช้สำหรับจำลองเอา auth ในการ dev บนเครื่อง-------//

  // const data = {
  //   user: {
  //     firstname: "admin tester",
  //     gender: "female",
  //     tel: "0844098902",
  //     birthdate: "2023-11-30",
  //     email: "asd@sad.com",
  //     img: "https://profile.line-scdn.net/0hoqbFQl6eMF5PPyNyX9BOIT9vMzRsTmlMal0qb38_bmclCHABZwx2bXI_aWp2X3IAZg53OntqPTpDLEc4UWnMakgPbW9zD38Ka1p4uQ",
  //     token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjUwNzAsIm1lbWJlcl9pZCI6ODU0MywiY3JlYXRlZF9hdCI6IjIwMjUtMDEtMDYgMTE6MTU6MDIifQ.Z2WJGW5whgevS2OAnnMuvZyrCEubXsEnT2sckBl0EzM"
  //   },
  //   tokens: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MjUwNzAsIm1lbWJlcl9pZCI6ODU0MywiY3JlYXRlZF9hdCI6IjIwMjUtMDEtMDYgMTE6MTU6MDIifQ.Z2WJGW5whgevS2OAnnMuvZyrCEubXsEnT2sckBl0EzM"
  // };

  // localStorage.setItem("auth", JSON.stringify(data));
  // localStorage.setItem("lineAccessToken", "eyJhbGciOiJIUzI1NiJ9.z64iAoEjXKmaQy5KH4nmplfHzDDfDcZxrclG_8k5a-UrSoL3IqiiwV7VP8ri9z7VJoD0LEPkj138Kl8_BlipfuVIUIiJ9n2XmBkPUXAMsit2gNCFvLWCTFPjxSrl-fkjiuXHYtWm5et5Ts4qRsdu0Wcw_qfUBsIBtLQ9z-ffA3w.H3zUcPmHGdCnW8Yjx5ktOZ_jBb3TmvyMtfK9twuPRig")

  // สำหรับไว้เช็คที่อยู่
  // localStorage.setItem("addressStatus", '0')

  //---- UAT ใช้สำหรับหลอกเอา auth ในการ dev บนเครื่อง-------//

  const auth = JSON.parse(window.localStorage.getItem("auth"));



  return auth && auth.user ? <Route {...rest} /> : <LoadingToRedirect />;
};

export default UserRoute;
