import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "bootstrap/dist/css/bootstrap.min.css";
import Menu from "../components/menu";
import { getProvince, getShopProvinces } from "../functions/getProvince";
import { getShopType } from "../functions/uploadReceipt";
import { getShopOnlineType } from "../functions/uploadReceipt";
import { getEventShop } from "../functions/uploadReceipt";
import { getReceiptProduct } from "../functions/uploadReceipt";
import { addReceipt } from "../functions/uploadReceipt";
import { LoadingOutlined } from "@ant-design/icons";
import { uploadImage } from "../functions/uploadImg";
import UploadReceipt from "../components/uploadReceipt";

import { InputNumber, Select, Space, Radio } from "antd";

const Upload_receipt = () => {
  const { user } = JSON.parse(window.localStorage.getItem("auth"));
  const token = user.token;
  const [type, setType] = useState("");
  const [img, setImg] = useState("");
  const [shop_id, setShop_id] = useState(0);
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState("");
  const [other_shop, seOther_shop] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxDate = new Date().toISOString().split("T")[0];
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("cat"); // Default type

  const handleTypeChange = (e) => {
    // setType(e.target.value);
    // if (e.target.value === "other") {
    //   setShop_id(5);
    // }
    setType(e);
    if (e === "other") {
      setShop_id(5);
    }
  };

  const handleRadioChange = (e) => {
    const newType = e.target.value;
    setSelectedType(newType);
    loadReceiptProduct(newType);
  };

  const handleChange = (value, type) => {
    // Preserve previously selected products
    const previousSelectedProducts = selectedProducts.filter((product) =>
      value.includes(product.id)
    );

    // Find and add newly selected products
    const newSelectedProducts = value
      .map((productId) => {
        const selectedProduct = ReceiptProduct.find(
          (item) => item.id === productId
        );
        if (selectedProduct) {
          return { id: selectedProduct.id, name: selectedProduct.name };
        } else {
          return null; // or handle the case when product is not found
        }
      })
      .filter(Boolean);

    // Combine previously selected and newly selected products
    const combinedSelectedProducts = [
      ...previousSelectedProducts,
      ...newSelectedProducts,
    ];

    // Remove duplicates from combinedSelectedProducts
    const uniqueSelectedProducts = combinedSelectedProducts.filter(
      (product, index, self) =>
        index === self.findIndex((p) => p.id === product.id)
    );

    setSelectedProducts(uniqueSelectedProducts);

    const updatedTypes = new Set(selectedProductTypes); // Create a copy of existing types
    updatedTypes.add(type); // Add the new type
    setSelectedProductTypes(updatedTypes);
  };

  useEffect(() => {
    loadProvince();
    loadShopType();
    loadShopOnlineType();
    loadEventShop();
    loadReceiptProduct(selectedType);
    setPrice("");
  }, []);

  useEffect(() => {
    loadReceiptProduct(selectedType);
  }, [selectedType]);

  const [ShopType, setShopType] = useState([]);
  const loadShopType = async () => {
    getShopType(token).then((res) => {
      setShopType(res.data.data);
    });
  };

  const [shopOnlineType, setShopOnlineType] = useState("");
  const loadShopOnlineType = async () => {
    getShopOnlineType(token).then((res) => {
      setShopOnlineType(res.data.data);
      setShop_id(res.data.data[0].id);
    });
  };

  const [EventShop, setEventShop] = useState("");
  const loadEventShop = async () => {
    getEventShop(token).then((res) => {
      if (res.data.data.length > 0) {
        setEventShop(res.data.data);
        setShop_id(res.data.data[0].id);
      } else {
        setEventShop([]);
        setShop_id(0);
      }
    });
  };

  //ดึงข้อมุลจังหวัดรวม
  const [provinces, setProvinces] = useState([]);
  const loadProvince = async () => {
    getProvince(token).then((res) => {
      setProvinces(res.data.data);
    });
  };

  //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [province, setProvince] = useState("");

  //ตัวแปรไว้เก็บค่าชื่อร้านค้าในจังหวัดทั้งหมด
  const [shopProvinces, setShopProvinces] = useState([]);

  //ฟังชัน onChang เมื่อเลือกจังหวัดแล้วจะส่งค่าไอดีมาเก็บไว้ที่ setProvince
  const handleProvince = (e) => {
    setProvince(e.target.value);

    //ส่งค่า api ของ id จังหวัดไปค้นหาชื่อร้านค่า (e.target.value)
    getShopProvinces(token, e.target.value).then((res) => {
      setShopProvinces(res.data.data);
    });
  };

  const [ReceiptProduct, setReceiptProduct] = useState([]);
  const loadReceiptProduct = async (type) => {
    try {
      const res = await getReceiptProduct(token, type);
      setReceiptProduct(res.data.data);
    } catch (error) {
      console.error("Error loading receipt products:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("selectedProducts", selectedProducts);
    // console.log("selectedProductTypes", selectedProductTypes);
    let pet_type;
    let product_id;
    if (selectedProductTypes.size === 1) {
      pet_type = selectedProductTypes.values().next().value;
    } else if (selectedProductTypes.size > 1) {
      pet_type = "both";
    } else {
      pet_type = "unknown";
    }
    product_id = JSON.stringify(selectedProducts);
    if (img === "") {
      alert("กรุณาอัพโหลดรูปภาพ");
    }

    if (type === "online" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "onsite" && shop_id === 0) {
      alert("กรุณาเลือกร้านค้า");
    }

    if (type === "event" && shop_id === 0) {
      alert("กรุณาเลือก Event");
    }

    setLoading(true);
    let data = new FormData();
    data.append("file", img);
    data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
    data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
    data.append("folder", "uat-moochie");

    let authtoken = token;

    try {
      const resp = await uploadImage(data);

      let receipt = {
        img: resp.data.secure_url,
        shop_id: shop_id,
        price: price,
        pet_type: pet_type,
        product_id: product_id,
        date: date,
        other_shop: other_shop,
      };

      addReceipt(authtoken, receipt)
        .then((res) => {
          setLoading(false);

          const page = "Upload_receipt";
          window.location.href = `alertSuscess/${page}`;
        })
        .catch((err) => {
          setLoading(false);
          console.log("err: ", err);
          alert("บันทึกไม่สำเร็จ");
        });
    } catch (error) {
      setLoading(false);
      console.log("ERROR :", error);
    }
  };

  const onChange = (value) => {
    setPrice(value);
  };

  return (
    <div>
      <div className="backgroundUpload">
        <div className=" d-flex align-items-center register_cam">
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="dog_icon"
          ></div>
          <b className="Header_register">&nbsp;&nbsp;ส่งใบเสร็จ&nbsp;&nbsp;</b>
          <div
            style={{
              backgroundColor: "transparent",
              borderColor: "transparent",
            }}
            className="cat_icon"
          ></div>
        </div>
        <br></br>
        <Form onSubmit={handleSubmit}>
          <center>
            <Form.Label className="">อัพโหลดภาพใบเสร็จ</Form.Label>
          </center>
          <div className="image_upload_receipt">
            <center><p style={{color:'red',marginTop:'100px'}}>* กิจกรรมสิ้นสุดแล้ว</p></center>
            {/* <UploadReceipt img={img} setImg={setImg} /> */}
          </div>
          <br></br>
          <Form.Group className="mb-3 input_money">
            <p style={{ color: "red", fontSize: "12px" }}>
              * ใบเสร็จเฉพาะวันที่ 4 มีนาคม 2567 - 30 พฤศจิกายน 2567
            </p>
            <Form.Label>ยอดเงินของคุณ</Form.Label>

            <br></br>

            <InputNumber
              type="tel"
              style={{ width: "100%" }}
              placeholder="ยอดเงินของคุณ"
              value={price}
              min={1}
              max={9999999}
              defaultValue={3}
              onChange={onChange}
            />
          </Form.Group>
          <div className="bath">&nbsp;บาท</div>
          <Form.Label style={{ width: "100%" }}>
            วันที่ซื้อ (ตามใบเสร็จ)
          </Form.Label>
          <input
            type="date"
            className="form-control"
            placeholder="วันเดือนปี"
            style={{ width: "100%" }}
            max={maxDate}
            onChange={(e) => setDate(e.target.value)}
          ></input>
          <br></br>
          <div id="boxproduct">
            <div id="product_box">
              <Space style={{ width: "100%" }} direction="vertical">
                <Radio.Group onChange={handleRadioChange} value={selectedType}>
                  <Space direction="vertical">
                    <Radio value="cat">Cat</Radio>
                    <Radio value="dog">Dog</Radio>
                  </Space>
                </Radio.Group>
                <Select
                  className="form-control"
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="เลือกสินค้าที่ซื้อ"
                  onChange={(value) => handleChange(value, selectedType)}
                  value={selectedProducts.map((product) => product.id)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {ReceiptProduct.map((item) => (
                    <Select.Option
                      key={`${item.id}-${item.name}`}
                      value={item.id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <div className="font_receipt">ซื้อจากที่ไหน</div>
          <Form.Label className="">ช่องทางการซื้อ</Form.Label>

          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="เลือกช่องทางการซื้อ"
            optionFilterProp="label"
            onChange={handleTypeChange}
            options={[
              {
                value: "online",
                label: "Online",
              },
              {
                value: "onsite",
                label: "Petshop",
              },
              {
                value: "event",
                label: "Event",
              },
            ]}
          />
          {/* 
          <Form.Select
            id="ddl"
            style={{ backgroundColor: "#F5F5F5" }}
            aria-label="Default select example"
            onChange={handleTypeChange}
          >
            <option>เลือกช่องทางการซื้อ</option>

            <option value="online">Online</option>
            <option value="onsite">Petshop</option>
            <option value="event">Event</option>
          </Form.Select> */}

          <br></br>

          {type === "online" && (
            <div id="box1">
              <div id="online_box">
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>เลือกช่องทางการซื้อ</option>
                  {shopOnlineType.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="ชื่อร้าน"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {shopOnlineType?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {type === "onsite" && (
            <div id="box2">
              <div className="dropdown_receipt_offline" id="offline_box">
                <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  onChange={handleProvince}
                  value={province}
                >
                  <option>จังหวัด</option>
                  {provinces.map((item) => (
                    <option value={item.id}>{item.name_th}</option>
                  ))}
                </Form.Select>
              </div>
              &nbsp;&nbsp;
              <div className="dropdown_receipt_offline">
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  aria-label="Default select example"
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option>ชื่อร้าน</option>
                  {shopProvinces.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="ชื่อร้าน"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {shopProvinces?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
          {type === "event" && (
            <div id="box3">
              <div id="event_box">
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  placeholder="เลือกช่องทางการซื้อ"
                  optionFilterProp="label"
                  onChange={(value) => setShop_id(value)}
                  filterOption={(
                    input,
                    option // Custom filter function
                  ) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {EventShop?.map((item, index) => (
                    <Select.Option key={`shop${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* <Form.Select
                  style={{ backgroundColor: "#F5F5F5" }}
                  value={shop_id}
                  onChange={(e) => setShop_id(e.target.value)}
                >
                  <option value="" selected="selected" disabled>
                    เลือกช่องทางการซื้อ
                  </option>
                  {EventShop.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select> */}
              </div>
            </div>
          )}
          <br></br>
          <br></br>
          <center>
            <Form.Group controlId="formBasicCheckbox">
              {loading ? (
                <LoadingOutlined className="text-danger" />
              ) : (
                <Button
                  style={{
                    backgroundColor: "#FF0099",
                    borderColor: "#FF0099",
                    width: "90px",
                  }}
                  variant="primary"
                  type="submit"
                  loadings
                 disabled
                >
                  บันทึก{" "}
                </Button>
              )}
            </Form.Group>
          </center>
        </Form>
        <div style={{ height: "100px" }}></div>
      </div>
      <Menu />
    </div>
  );
};

export default Upload_receipt;
