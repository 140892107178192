import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Spin, Col, Row } from "antd";
import { getLogin } from "../functions/member";
import { userID_ } from "../functions/userAddress";
import { useDispatch } from "react-redux";
import { get } from "cloudinary/lib/cache";

const Login = () => {
  // const liff = window.liff;
  let history = useHistory();
  localStorage.removeItem("auth");
  // const token = localStorage.getItem("accessToken");
  const [hasError, setHasError] = useState(false);

  // useEffect(async () => {

  //   localStorage.removeItem("auth");

  //   const fetchData = async () =>{
  //   liff
  //     .init({ liffId: '1660688630-NeqEEQ62' })
  //     .then(async () => {
  //       if (liff.isLoggedIn()) {
  //         let lineAccessToken = liff.getAccessToken();
  //         localStorage.setItem("lineAccessToken", lineAccessToken);

  //         liff
  //           .getProfile()
  //           .then((profile) => {
  //             handleLogin(profile, lineAccessToken)
  //           })
  //           .catch((err) => {
  //             console.error("err - 5", err)
  //             setHasError(true)
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err - 6",err);
  //       setHasError(true)
  //     });
  //   }

  //   await fetchData()
  // }, [token, history]);

  useEffect(() => {
    const lineToken = localStorage.getItem("lineToken");
    const lineTokenProd = localStorage.getItem("lineTokenProd");
    const lineProfileProd = localStorage.getItem("lineProfileProd");
    const lineProfile = localStorage.getItem("lineProfile");
    const lineProfile2 = localStorage.getItem("lineProfile2");
    // string to object
    const _lineProfile = JSON.parse(lineProfile);
    const _lineProfile2 = JSON.parse(lineProfile2);
    const _lineProfile3 = JSON.parse(lineProfileProd);

    const data = {
      line_id: _lineProfile.userId,
      line_id_2: _lineProfile2.userId,
      line_id_3: _lineProfile3.userId
    }

    getLogin(lineToken, data, lineTokenProd)
      .then((res) => {
        if (res.status === 404 || res.status === 400) {
          history.push("/register");
        } else if (res.status === 200) {
          let logins = res.data;

          localStorage.setItem("accessToken", logins.data.session);
          localStorage.setItem("addressStatus", logins.address_status);
          localStorage.setItem("petStatus", logins.pet_status);
          localStorage.setItem("member_status", logins.member_status);
          localStorage.setItem("accepted_campaign", logins.accepted_campaign);

          userID_(logins.data.session)
            .then((res) => {
              let resProfile = res.data.data;

              const data = {
                user: {
                  firstname: resProfile.firstname,
                  surename: resProfile.surename,
                  gender: resProfile.gender,
                  tel: resProfile.tel,
                  birthdate: resProfile.birthdate,
                  email: resProfile.email,
                  img: resProfile.img,
                  token: logins.data.session,
                },
                tokens: logins.data.session,
              };

              window.localStorage.setItem("auth", JSON.stringify(data));

              // production ปกติ -------------
              window.location.href = "/home";
              //-------------------------

              //UAT meet&Greet
              // window.location.href = "/select_campaign";
              //UAT meet&Greet
            })
            .catch((err) => {
              console.log(err);
              setHasError(true)
            });
        }
      })
      .catch((err) => {
        console.log("🚀 ~ useEffect ~ err:", err);
        setHasError(true)
      });
  }, [history]);

  // const handleLogin = async (profile, lineAccessToken) =>{
  //   getLogin({
  //     line_id: profile.userId,
  //     access_token: lineAccessToken,
  //   })
  //     .then((res) => {
  //       console.log("res res", res);
  //       if (res.status === 404 || res.status === 400) {
  //         history.push("/register");
  //       } else if (res.status === 200) {
  //         let logins = res.data;

  //         localStorage.setItem("accessToken", logins.data.session);
  //         localStorage.setItem("addressStatus",logins.address_status);
  //         localStorage.setItem("petStatus", logins.pet_status);
  //         localStorage.setItem("member_status", logins.member_status);

  //         userID_(logins.data.session)
  //           .then((res) => {
  //             let resProfile = res.data.data;

  //             const data = {
  //               user: {
  //                 firstname: resProfile.firstname,
  //                 surename: resProfile.surename,
  //                 gender: resProfile.gender,
  //                 tel: resProfile.tel,
  //                 birthdate: resProfile.birthdate,
  //                 email: resProfile.email,
  //                 img: resProfile.img,
  //                 token: logins.data.session,
  //               },
  //               tokens: logins.data.session,
  //             }

  //             window.localStorage.setItem("auth", JSON.stringify(data));

  //             window.location.href = "/home";
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //             setHasError(true)
  //           });
  //       }

  //     })
  //     .catch((err) => {
  //       setHasError(true)
  //     });
  // }

  if (hasError) {
    window.location.href = "/register";
  }

  return (
    <div style={{ height: "100vh" }}>
      <div className="backgroundHome">
        <Row justify="space-around" align="middle" className="h-100">
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
